import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "reactstrap"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <section id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="section-heading">Let's Get In Touch!</h2>
            <hr className="my-4" />
            <p>
              Want to learn more about how Mike changes kids' lives? Please
              contact us!
            </p>
          </div>
        </div>
        <div className="row sr-contact">
          <div className="col-lg-8 mx-auto text-center">
            <Button
              outline
              color="primary"
              className="typeform-share button"
              href="https://byster.typeform.com/to/OmgIol"
              data-mode="popup"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Form
            </Button>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Contact
